@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap");
body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
* {
  font-family: "JetBrains Mono", monospace;
}
#controllers-container {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
#controllers-container > *:not(:first-child) {
  margin-left: 0.5rem;
}
#user-id {
  flex-grow: 1;
  padding: 8px;
  min-width: 0;
}
button {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.07) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 1px 8px 0px;
  background-color: white;
  border: 1px solid #cecece;
  border-radius: 4px;
  padding: 4px 8px;
}
.primary {
  border-color: #831fad;
  background-color: #9c27b0;
  color: white;
}
#feezback-aggregate-iframe {
  flex-grow: 1;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.07) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 1px 8px 0px;
}

.app-mobile {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.app-mobile #feezback-aggregate-iframe {
  height: unset;
  border: none;
  border-radius: 0;
  box-shadow: none;
  z-index: 1;
  flex-grow: 1;
}

.app-mobile #controllers-container {
  margin-bottom: 0;
  z-index: 2;
  padding: 1.5vw;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.07) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 1px 8px 0px;
  background-color: white;
}

.app-mobile #controllers-container > * {
  flex-shrink: 1;
}

.app-mobile #controllers-container > textarea {
  flex-shrink: 2;
}

.app-mobile #controllers-container > *:not(:first-child) {
  margin-left: 1vw;
}

.App, .app-mobile {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

#main {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

#theme-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50%;
  margin-right: 0.25rem;
}

#theme-editor.loading {
  align-items: center;
  justify-content: center;
}

#theme-editor > .menu {
  display: flex;
  margin-bottom: 0.25rem;
}
#theme-editor > .menu > input {
  padding: 0.5rem;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 0;
  flex-grow: 1;
}
#theme-editor > .menu > button {
  margin-left: 0.25rem;
}

.jsoneditor {
  border-width: 0 !important;
}
.jsoneditor-react-container {
  height: 100%;
  width: 100%;
  margin: 0px;
  border: 1px solid #cecece;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.07) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 1px 8px 0px;
}
.jsoneditor-menu {
  border-color: #831fad !important;
  background-color: #9c27b0 !important;
  font-family: "JetBrains Mono" !important;
}
.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button,
.jsoneditor-menu a.jsoneditor-poweredBy {
  font-family: "JetBrains Mono" !important;
}
.jsoneditor-contextmenu .jsoneditor-menu li {
  background-color: white !important;
}
button.jsoneditor-type-modes.jsoneditor-selected {
  background-color: #9c27b0 !important;
  border-radius: 0;
}
.ace-jsoneditor .ace_marker-layer .ace_active-line {
  background: #f0f0f0 !important;
}
textarea.jsoneditor-text.ace_editor,
.ace-jsoneditor.ace_editor,
.jsoneditor-statusbar {
  font-size: 0.8rem !important;
  font-family: "JetBrains Mono" !important;
}
.ace-jsoneditor .ace_string {
  color: #831fad !important;
}
.ace-jsoneditor .ace_variable {
  color: #222222 !important;
}
.ace-jsoneditor .ace_constant.ace_language {
  color: #697bd4 !important;
}
.ace-jsoneditor .ace_constant.ace_numeric {
  color: #697bd4 !important;
}
